<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="showDialog"
      width="600px"
      top="3%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span v-loading="loading" class="content-box">
        <div class="questionnaire-box">
          <div
            class="questionnaire-item"
            v-for="(questionItem, questionIndex) in renderInfo.QuestionList"
            :key="questionIndex"
          >
            <div class="main">
              <div class="title">
                <!-- <span class="required">*</span> -->
                <span>{{
                  `${questionIndex + 1}.${questionItem.QuestionTitle}【${
                    questionItem.QuestionTypeName
                  }】`
                }}</span>
              </div>

              <div class="answer-box">
                <template
                  v-if="
                    questionItem.QuestionTypeName === '单选题' ||
                      questionItem.QuestionTypeName === '多选题'
                  "
                >
                  <el-row :gutter="20">
                    <div
                      class="answer-item"
                      v-for="(answerItem,
                      answerIndex) in questionItem.AnswerList"
                      :key="answerIndex"
                    >
                      <el-col :span="11">
                        <span>{{ answerItem.DispalyText }}</span>
                      </el-col>
                    </div>
                  </el-row>
                </template>
                <template
                  v-if="
                    questionItem.QuestionTypeName === '评分题' ||
                      questionItem.QuestionTypeName === '填写题'
                  "
                >
                  <div
                    class="answer-item"
                    v-for="(answerItem, answerIndex) in questionItem.AnswerList"
                    :key="answerIndex"
                  >
                    <span>{{ answerItem.DispalyText }}</span>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </span>
      <span slot="footer">
        <el-button @click="onCloseDialog">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Survey from "@/api/survey.js";

export default {
  data() {
    return {
      title: "",
      formId: 0,
      renderInfo: {
        QuestionList: [], // 问卷详情
        ThemeId: 0,
        ThemeName: "",
      },
      showDialog: false,
      loading: false,
    };
  },

  computed: {
    changeQuestionType() {
      return function(type) {
        switch (type) {
          case "singlecho":
            return "单选";
          case "multicho":
            return "多选";
          case "scorecho":
            return "评分";
          default:
            return "文本";
        }
      };
    },
  },

  mounted() {},

  methods: {
    // 获取会员问卷详情
    async getFormDetail() {
      try {
        let { data } = await Survey.getFormDetail({
          form_id: this.formId,
        });
        this.renderInfo = data;
      } catch (err) {
        console.log(err);
      }
    },

    // 打开对话框
    onShowDialog(event) {
      this.formId = event.FormId;
      this.title = event.MemberName + '的问卷详情';
      this.getFormDetail();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  .questionnaire-box {
    .flex-col;
    align-items: unset;
    max-height: 70vh;
    overflow-y: auto;

    .questionnaire-item {
      .flex-row;
      align-items: unset;
      margin-block: 5px;
      box-sizing: border-box;
      border: 1px solid #ccc;
      border-radius: 5px;

      .main {
        .flex-col;
        align-items: unset;
        flex: 1;
      }

      .title {
        margin: 10px;
        font-size: 16px;
        .required {
          color: red;
        }
      }

      .answer-box {
        margin: 0 10px 10px;
        .answer-item {
          font-size: 16px;

          .rate {
            .flex-row;
            padding: 0 10px;
            > div {
              .flex-row;
              justify-content: center;
              flex: 1;
              height: 30px;
              border: 1px solid #ccc;
            }
          }
        }
      }
    }
  }
}

.scrollbar;
</style>
