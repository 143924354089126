<template>
  <div>
    <el-dialog
      title="问卷使用明细"
      :visible.sync="showDialog"
      width="800px"
      top="3%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span class="content-box">
        <div class="df-row">
          <div class="df-col">
            <el-input
              v-model="submitData.word"
              placeholder="请输入会员名称或手机号"
              size="mini"
              clearable
            ></el-input>
          </div>
          <div class="df-col">
            <el-button type="primary" size="mini" @click="onSearch"
              >查询</el-button
            >
          </div>
        </div>

        <el-table
          v-loading="loading"
          :data="tableData"
          max-height="600px"
          border
          stripe
        >
          <el-table-column prop="MemberName" label="会员名称"></el-table-column>
          <el-table-column prop="MemberPhone" label="会员手机"></el-table-column>
          <el-table-column prop="" label="提交时间">
            <template slot-scope="scope">
              {{ scope.row.CreateTime ? util.dateFormat('YYYY-mm-dd HH:MM:SS', new Date(scope.row.CreateTime * 1000)) : '' }}
            </template>
          </el-table-column>
          <el-table-column prop="" label="操作" width="120">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="onShowDetail(scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </span>
      <span slot="footer" class="footer-box">
        <el-pagination
          background
          :page-sizes="[15, 20, 30, 40]"
          :page-size="submitData.page_size"
          :current-page="submitData.page_index"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalRecord"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
        <div>
          <el-button @click="onCloseDialog">取 消</el-button>
        </div>
      </span>
    </el-dialog>

    <MemberHistoryDetail ref="MemberHistoryDetail"></MemberHistoryDetail>
  </div>
</template>

<script>
import Survey from "@/api/survey.js";
import MemberHistoryDetail from "./member-history-detail.vue";

export default {
  components: { MemberHistoryDetail },

  data() {
    return {
      totalRecord: 0,
      tableData: [{}],
      submitData: {
        word: "",
        usage_id: 0,
        page_size: 20,
        page_index: 1,
      },
      showDialog: false,
      loading: false,
    };
  },

  methods: {
    // 获取明细列表
    async getUsageFormList() {
      this.loading = true;
      try {
        let { data } = await Survey.getUsageFormList(this.submitData);
        this.tableData = data.list;
        this.totalRecord = data.filter.TotalRecord;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    onSearch() {
      this.submitData.page_index = 1;
      this.getUsageFormList();
    },

    onShowDetail(event) {
      this.$refs.MemberHistoryDetail.onShowDialog(event);
    },

    // 改变页面数据条数
    handleSizeChange(event) {
      this.submitData.page_index = 1;
      this.submitData.page_size = event;
      this.getUsageFormList();
    },

    // 改变页面码
    handleCurrentChange(event) {
      this.submitData.page_index = event;
      this.getUsageFormList();
    },

    // 打开对话框
    onShowDialog(event) {
      this.submitData.usage_id = event.UsageId;
      this.getUsageFormList();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
}

.footer-box {
  .flex-row;
  justify-content: space-between;
}
</style>
