<template>
  <div>
    <el-dialog
      title="问卷统计数据"
      :visible.sync="showDialog"
      width="600px"
      top="3%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span v-loading="loading" class="content-box">
        <div class="questionnaire-box">
          <div
            class="questionnaire-item"
            v-for="(questionItem, questionIndex) in renderInfo.QuestionList"
            :key="questionIndex"
          >
            <div class="main">
              <div class="title">
                <!-- <span class="required">*</span> -->
                <span>{{
                  `${questionIndex + 1}.${questionItem.QuestionTitle}【${
                    questionItem.QuestionTypeName
                  }】`
                }}</span>
              </div>

              <div class="answer-box">
                <div
                  class="answer-item"
                  v-for="(statisItem, statisIndex) in questionItem.StatisList"
                  :key="statisIndex"
                >
                  <div v-if="statisItem.Type == 'rate'" class="rate">
                    <span>{{ statisItem.Text }}</span>
                    <el-progress
                      :stroke-width="10"
                      :percentage="statisItem.Value * 100"
                    ></el-progress>
                  </div>
                  <div v-else-if="statisItem.Type == 'avg'" class="avg">
                    <span>平均数：{{ statisItem.Value }} 分</span>
                  </div>
                  <div v-else-if="statisItem.Type == 'text'" class="text">
                    <el-button
                      type="primary"
                      size="mini"
                      @click="
                        getUsageTextList(statisItem, questionItem.QuestionTitle)
                      "
                      >查看</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </span>
      <span slot="footer">
        <el-button @click="onCloseDialog">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="textTitle"
      :visible.sync="textShow"
      width="600px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="textShow = false"
    >
      <span class="content-box">
        <el-table :data="textList" max-height="800px" border stripe>
          <el-table-column prop="TextValue" label="文本内容"> </el-table-column>
        </el-table>

        <el-pagination
          style="text-align: right; margin-top: 20px"
          background
          :page-sizes="[15, 20, 30, 40]"
          :page-size="textSubmitData.page_size"
          :current-page="textSubmitData.page_index"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalRecord"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </span>
      <span slot="footer">
        <el-button @click="textShow = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Survey from "@/api/survey.js";

export default {
  data() {
    return {
      usageId: 0,
      totalRecord: 0,
      textTitle: "",
      textList: [],
      renderInfo: {
        QuestionList: [], // 问卷详情
        ThemeId: 0,
        ThemeName: "",
      },
      textSubmitData: {
        answer_id: 0,
        page_index: 1,
        page_size: 20,
      },
      showDialog: false,
      textShow: false,
      loading: false,
    };
  },

  computed: {
    format(percentage) {
      return `${percentage}%`;
    },
  },

  mounted() {},

  methods: {
    // 获取问卷统计数据
    async getUsageStatisData() {
      this.loading = true;
      try {
        let { data } = await Survey.getUsageStatisData({
          usage_id: this.usageId,
        });
        this.renderInfo = data;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    //
    async getUsageTextList(event, questionTitle) {
      this.textSubmitData.answer_id = event.AnswerId;
      this.textTitle = "题目：" + questionTitle;
      try {
        let { data } = await Survey.getUsageTextList({
          usage_id: this.usageId,
          ...this.textSubmitData,
        });
        this.textList = data.list;
        this.totalRecord = data.filter.TotalRecord;
        this.textShow = true;
      } catch (err) {
        console.log(err);
      }
    },

    // 改变页面数据条数
    handleSizeChange(event) {
      this.submitData.page_index = 1;
      this.submitData.page_size = event;
      this.getUsageTextList();
    },

    // 改变页面码
    handleCurrentChange(event) {
      this.submitData.page_index = event;
      this.getUsageTextList();
    },

    // 打开对话框
    onShowDialog(event) {
      this.usageId = event.UsageId;
      this.getUsageStatisData();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  .questionnaire-box {
    .flex-col;
    align-items: unset;
    max-height: 70vh;
    overflow-y: auto;

    .questionnaire-item {
      .flex-row;
      align-items: unset;
      margin-block: 5px;
      box-sizing: border-box;
      border: 1px solid #ccc;
      border-radius: 5px;

      .main {
        .flex-col;
        align-items: unset;
        flex: 1;
      }

      .title {
        margin: 10px;
        font-size: 16px;
        .required {
          color: red;
        }
      }

      .answer-box {
        margin: 0 10px 10px;
        .answer-item {
          font-size: 16px;

          .rate {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

.scrollbar;
</style>
