<template>
  <div class="history">
    <div class="df-row">
      <div class="df-col">
        <el-input
          v-model="submitData.word"
          placeholder="请输入关键字"
          size="mini"
          clearable
          @change=""
        ></el-input>
      </div>
      <div class="df-col">
        <el-button type="primary" size="mini" @click="onSearch">查询</el-button>
      </div>
    </div>

    <el-table
      v-loading="loading"
      :data="tableData"
      :max-height="tableMaxHeight"
      border
      stripe
    >
      <el-table-column prop="ThemeName" label="名称"></el-table-column>
      <el-table-column prop="UsageTypeText" label="使用类型"></el-table-column>
      <el-table-column prop="" label="使用时间">
        <template slot-scope="scope">
          {{
            scope.row.UsageTime
              ? util.dateFormat(
                  "YYYY-mm-dd HH:MM:SS",
                  new Date(scope.row.UsageTime * 1000)
                )
              : ""
          }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="问卷统计">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="onShowTotal(scope.row)"
            >查看</el-button
          >
        </template>
      </el-table-column>
      <el-table-column prop="" label="使用明细">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="onShowDetail(scope.row)"
            >查看</el-button
          >
        </template>
      </el-table-column>
      <el-table-column prop="" label="使用状态">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.UsageStatus == 1 ? 'success' : 'danger'"
            size="small"
            effect="light"
            >{{ scope.row.UsageStatusText }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="" label="操作">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.UsageStatus == 1"
            type="danger"
            size="mini"
            :disabled="disableDisabled"
            @click="disableStoreUsage(scope.row)"
            >停用</el-button
          >
          <el-button
            v-else
            type="warning"
            size="mini"
            :disabled="disableDisabled"
            @click="disableStoreUsage(scope.row, 1)"
            >恢复</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      background
      :page-sizes="[15, 20, 30, 40]"
      :page-size="submitData.page_size"
      :current-page="submitData.page_index"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecord"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>

    <!-- 问卷统计对话框 -->
    <QuestionTotalDialog ref="QuestionTotalDialog"></QuestionTotalDialog>

    <!-- 使用问卷明细对话框 -->
    <HistoryDetailDialog ref="HistoryDetailDialog"></HistoryDetailDialog>
  </div>
</template>

<script>
import Survey from "@/api/survey.js";

import QuestionTotalDialog from "./components/question-total-dialog";
import HistoryDetailDialog from "./components/history-detail-dialog";

export default {
  components: { QuestionTotalDialog, HistoryDetailDialog },

  data() {
    return {
      tableMaxHeight: 0,
      totalRecord: 0,
      tableData: [],
      submitData: {
        word: "",
        page_index: 1,
        page_size: 20,
      },
      loading: false,
      disableDisabled: false,
    };
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);

    this.getStoreUsageMaintList();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取问卷使用记录
    async getStoreUsageMaintList() {
      this.loading = true;
      try {
        let { data } = await Survey.getStoreUsageMaintList(this.submitData);
        this.tableData = data.list;
        this.totalRecord = data.filter.TotalRecord;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // 停用问卷
    disableStoreUsage(event, reverse = 0) {
      this.$confirm(
        `确定${reverse ? "恢复" : "停用"}该问卷的使用吗？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(async () => {
        this.disableDisabled = true;
        try {
          let { errcode } = await Survey.disableStoreUsage({
            usage_id: event.UsageId,
            reverse,
          });
          if (errcode == 0) {
            this.$message.success((reverse ? "恢复" : "停用") + "成功");
            this.getStoreUsageMaintList();
          }
        } catch (err) {
          console.log(err);
        } finally {
          this.disableDisabled = false;
        }
      });
    },

    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 68 + 40);
    },

    // 查询按钮点击事件
    onSearch() {
      this.submitData.page_index = 1;
      this.getStoreUsageMaintList();
    },

    // 打开问卷统计对话框
    onShowTotal(event) {
      this.$refs.QuestionTotalDialog.onShowDialog(event);
    },

    // 打开使用问卷明细对话框
    onShowDetail(event) {
      this.$refs.HistoryDetailDialog.onShowDialog(event);
    },

    // 改变页面数据条数
    handleSizeChange(event) {
      this.submitData.page_index = 1;
      this.submitData.page_size = event;
      this.getStoreUsageMaintList();
    },

    // 改变页面码
    handleCurrentChange(event) {
      this.submitData.page_index = event;
      this.getStoreUsageMaintList();
    },
  },
};
</script>

<style lang="less" scoped>
.history {
  .df-row {
    margin-top: 20px;
  }

  .el-pagination {
    margin-top: 20px;
    text-align: right;
  }
}
</style>
